import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.checkVisibility();
    document.addEventListener("scroll", () => this.checkVisibility());
  }

  disconnect() {
    document.removeEventListener("scroll", () => this.checkVisibility());
  }

  closeModal(e) {
    e.preventDefault();
    this.element.remove()
  }

  checkVisibility() {
    const showOffset = 500; // The scroll position at which to show the CTA bar

    if (window.pageYOffset > showOffset) {
      this.element.classList.add("cta-bar--show");
    } else {
      this.element.classList.remove("cta-bar--show");
    }
  }
}
