import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cartItemCount", "titleLineItemForm", "item", "itemAmount", "cartTotalAmount"]

  onAddLineItemSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.titleLineItemFormTarget.innerHTML = data.html;
    this.updateCartItemCount()
  }

  changeLineItemAmount(event) {
    this.index = event.target.getAttribute("data-line-item-index")
    Rails.fire(event.target.form, 'submit')
  }

  lineItemUpdated(event) {
    let [data, status, xhr] = event.detail;
    this.cartTotalAmountTarget.innerHTML = parseFloat(data.cart_total).toFixed(2).replace(".", ",")
    this.itemAmountTargets[this.index].innerHTML = parseFloat(data.line_item_total).toFixed(2).replace(".", ",")
    this.updateCartItemCount()
  }

  // PRIVATE
  updateCartItemCount() {
    Rails.ajax({
      type: "GET",
      url: '/line_items',
      dataType: 'json',
      success: (data) => {
        this.cartItemCountTarget.innerHTML = data.line_item_count
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
  }
}
