import { Controller } from "stimulus"

import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

export default class extends Controller {
  static targets = ["quotes", "titles"]

  connect() {
    try { this.quoteSwiper } catch(err) {}
    try { this.titleSwiper } catch(err) {}
  }

  get quoteSwiper() {
    Swiper.use([Pagination])

    new Swiper(this.quotesTarget, {
      fadeEffect: {
        crossFade: true
      },
      slidesPerView: 1,
      centeredSlides: true,
      loop: false,
      initialSlide: 0,
      speed: 500,
      spaceBetween: 20,
      watchOverflow: true,
      autoplay: {
        delay: 4000,
      },
      pagination: {
        el: '.swiper-pagination-quotes',
        clickable: true,
      },
      effect: 'fade'
    });
  }

  get titleSwiper() {
    Swiper.use([Pagination])

    new Swiper(this.titlesTarget, {
      centeredSlides: false,
      loop: false,
      initialSlide: 0,
      watchOverflow: true,
      speed: 500,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination-titles',
        clickable: true,
      },
      autoplay: {
        delay: 4000,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 20
        },
        480: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 30
        }
      }
    });
  }

}
