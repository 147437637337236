import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "link", "block" ]

  connect() {
    if(!(window.location.hash)) {
      this.open()
    }
  }

  toggle(e) {
    e.preventDefault()
    this.open()
  }

  open(){
    var link = this.linkTarget
    var block = this.blockTarget
    if (block.style['display'] != 'none') {
      block.style.display = "none"
      link.text = "KOMMENTARE ANZEIGEN"
    } else {
      block.style.display = "block"
      link.text = "KOMMENTARE VERBERGEN"
    }
  }
}
