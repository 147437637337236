import { Controller } from "stimulus"

export default class extends Controller {

  toggleSelect(event) {
    var target = document.getElementById(event.target.dataset.targetId)

    if (event.target.checked) {
      target.classList.add("hide")
    } else {
      target.classList.remove("hide")
    }
  }

  toggleRadioButton(event) {
    var selected = event.target
    var target = document.getElementById(selected.dataset.targetId)

    if (target === null) return

    if (selected.value === "direct_debit") {
      target.classList.remove("hide")
    } else {
      target.classList.add("hide")
    }
  }

}
