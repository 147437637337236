// images
require.context('../images', true)
require.context('../fonts', true)

import Rails from '@rails/ujs';
Rails.start();
global.Rails = Rails;
require("turbolinks").start();

import Velocity from 'velocity-animate';

// Stylesheets
require('stylesheets/application.sass');

// Javascript
require('src/fontawesome.js');

import "controllers/application"
