import { Controller } from "stimulus"
import Readmore from 'readmore-js';

export default class extends Controller {
  static targets = [ "text", "lessControl", "moreControl"]

  initialize() {
    this.readmore = new Readmore(this.textTarget, {
      speed: 575,
      collapsedHeight: 180,
      heightMargin: 28,
      lessLink: '<a href="#" class="readmore has-text-centered mt-4" data-target="readmore.lessControl"><i class="fa fa-chevron-up"></i></a>',
      moreLink: '<a href="#" class="readmore has-text-centered mt-4" data-target="readmore.moreControl"><i class="fa fa-chevron-down"></i></a>'
    });
  }

  disconnect() {
    try { this.moreControlTarget.remove() } catch(err) {}
    try { this.lessControlTarget.remove() } catch(err) {}
    this.readmore.destroy();
  }

  toggle() {
    this.readmore.toggle(this.textTarget);
  }
}
