import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["node"]

  connect() {
    this.blickInsBuch
  }

  get blickInsBuch() {
    var ean = this.nodeTarget.dataset.ean
    var book = new MX_BookPreview('5753939523', ean, 1, 3)
    book.setCntId('7827854133')
    book.setButtonId('standard')
    book.setIncludeDiv('blickInsBuch')
    book.show()
  }
}
