import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "header", "body"]

  toggle(event) {
    var faqBody = this.bodyTarget
    var faqHeader = this.headerTarget

    if (faqBody.style['display'] != 'none') {
      var slideDir = 'slideUp';
      faqHeader.classList.remove("expanded");
    } else {
      var slideDir = 'slideDown';
      faqHeader.classList.add("expanded");
    }
    Velocity(faqBody, slideDir, {duration: 400, easing: 'ease-in-out' })
  }
}
