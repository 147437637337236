import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle"
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons/faChevronCircleLeft"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation"
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons/faHandPointRight"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart"
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt"
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash"
import { faArrowsAltV } from "@fortawesome/free-solid-svg-icons/faArrowsAltV"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"

import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare"
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons/faInstagramSquare"

import { library, dom } from "@fortawesome/fontawesome-svg-core"

library.add(faPlus)
library.add(faInfoCircle)
library.add(faBolt)
library.add(faChevronCircleLeft)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faExclamation)
library.add(faHandPointRight)
library.add(faTimes)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faMapMarkerAlt)
library.add(faShoppingCart)
library.add(faSearch)
library.add(faPencilAlt)
library.add(faTrash)
library.add(faArrowsAltV)
library.add(faArrowRight)

library.add(faFacebookSquare)
library.add(faTwitterSquare)
library.add(faInstagramSquare)

dom.watch()
